.footer {
    height: 80vh;
    display: flex;
    justify-content: center;
    padding: 20px 20px 0px 20px;
    background-color: var(--text-color);
}

.footerContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 60%;
}

.menuContainer {
    display: flex;
    gap: 10px;
}

.menuBlock {
    width: 180px;
    list-style-type: none;
}

.menuBlock ul {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.listHeader {
    font-size: 12px;
    color: var(--text-seondary-color);
}

.link a {
    color: var(--background-color);
    text-decoration: none;
}

.link a:hover {
    text-decoration: underline;
}

.link a:visited {
    color: var(--background-color);
    text-decoration: none;
}

.link a > .disabled a {
    color: var(--text-seondary-color);
}

.copyWrite {
    margin-top: auto;
    font-size: 10px;
    display: flex;
    justify-content: center;
    padding: 8px;
}

@media screen and (max-width: 800px) {
    .footerContainer {
        width: 70%;
    }
}

@media screen and (max-width: 700px) {
    .footerContainer {
        width: 80%;
    }
}

@media screen and (max-width: 600px) {
    .footerContainer {
        align-items: center;
        width: 90%;
    }

    .menuContainer {
        flex-direction: column;
    }

    .menuBlock {
        justify-content: center;
    }

    .menuBlock ul {
        align-items: center;
    }
    .footerContainer {
        gap: 40px;
    }
}