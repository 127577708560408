/* Carousel.css */
.carousel-wrapper {
    position: relative;
    left: 0;
    overflow: hidden;
  }
  
.swiper-wrapper {
    left: calc(50% - 30%);
    width: 60%;
    padding-top: 10px;
}

.custom-navigation-wrapper {
    display: flex;
    justify-content: center;
}

.custom-navigation {
    position: relative;
    justify-content: flex-end;
    display: flex;
    width: 60%;
    gap: 10px;
}
  
.custom-nav-btn {
    background: #333;
    color: #fff;
    border: none;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
}
  
.custom-nav-btn:hover {
    background: #555;
}
  
.carousel-container {
    width: 100vw;
}
  
.swiper-slide {
    min-width: 285px;   /* Ensures a fixed width */
    max-width: 285px;
    height: 350px;      /* Fixed height */
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.swiper-slide:last-child {
    margin-right: calc(100vw - 280px);
}


/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 800px) {
    .swiper-wrapper {
        width: 70%;
        left: calc(50% - 35%);
        
    }
    .custom-navigation {
        width: 70%;
    }
}

@media screen and (max-width: 700px) {
    .swiper-wrapper {
        width: 80%;
        left: calc(50% - 40%);
    }
    .custom-navigation {
        width: 80%;
    }
}

@media screen and (max-width: 600px) {
    .swiper-wrapper {
        width: 90%;
        left: calc(50% - 45%);
    }
    .custom-navigation {
        width: 90%;
    }
}