.mySwiper  .swiper-wrapper{
    left: 0;
}
  
.mySwiperSlide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
}
