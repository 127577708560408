.contactWrapper {
    background-color: var(--background-color);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0px;
}

.contactContainer {
    width: 60%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.contactContent {
    
}

.contactContent h1 {
    color: var(--primary-color);
    font-weight: 900;
}

.contactContent h4 {
    color: var(--text-color);
    font-weight: 300;
}

.contactFormContainer {
    display: flex;
    flex-direction: column;
    background-color: var(--text-color);
    border-radius: 20px;
    padding: 20px;
}

.contactForm {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.contactForm input, textarea {
    width: 300px;
    background: none;
    border: 2px solid var(--text-seondary-color);
    border-radius: 5px;
    font-size: 16px;
    padding: 5px 10px;
}

.contactButton {
    margin-top: 20px;
    background-color: var(--primary-color);
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    color: var(--text-color);
    font-weight: 800;
    cursor: pointer;
}

.contactButton:hover {
    background-color: var(--primary-color-hover);
}

@media screen and (max-width: 1000px) {
    .contactContainer {
        flex-direction: column;
    }
    .contactForm input, textarea {
        width: 100%;
        box-sizing: border-box;
    }
}

@media screen and (max-width: 800px) {
    .contactContainer {
        width: 70%;
    }
}

@media screen and (max-width: 700px) {
    .contactContainer {
        width: 80%;
    }
}

@media screen and (max-width: 600px) {
    .contactContainer {
        width: 90%;
    }
}