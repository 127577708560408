:root {
  --primary-color: #FD792F;
  --primary-color-hover: #DD5B12;
  --background-color: #1F1F1F;
  --text-color: #FFF5ED;
  --text-seondary-color: #555555;
  --figma-green: #0ACF83;
  --figma-red: #F24E1E;
  --figma-pink: #FF7262;
  --figma-purple: #A259FF;
  --figma-blue: #1ABCFE;
}

h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}

body, input, button {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'M PLUS Rounded 1c', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.m-plus-rounded-1c-thin {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.m-plus-rounded-1c-light {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.m-plus-rounded-1c-regular {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.m-plus-rounded-1c-medium {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.m-plus-rounded-1c-bold {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.m-plus-rounded-1c-extrabold {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.m-plus-rounded-1c-black {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.space-mono-regular {
  font-family: "Space Mono", monospace;
  font-weight: 400;
  font-style: normal;
}

.space-mono-bold {
  font-family: "Space Mono", monospace;
  font-weight: 700;
  font-style: normal;
}

.space-mono-regular-italic {
  font-family: "Space Mono", monospace;
  font-weight: 400;
  font-style: italic;
}

.space-mono-bold-italic {
  font-family: "Space Mono", monospace;
  font-weight: 700;
  font-style: italic;
}
