.mainContainer {
    background-color: var(--background-color);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0px;
}

.resumeWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 60%;
}

.resumeWrapper h1 {
    font-weight: 900;
    color: var(--text-seondary-color);
}

.profileWrapper {
    color: var(--text-color);
}

.profileHeader {
    display: flex;
    justify-content: space-between;
}

.downloadButton {
    background-color: var(--primary-color);
    border: none;
    border-radius: 20px;
    height: 40px;
    box-sizing: border-box;
    padding: 10px 20px;
    color: var(--text-color);
    font-weight: 800;
    cursor: pointer;
}

.downloadButton:hover {
    background-color: var(--primary-color-hover);
}

.experienceWrapper {
    color: var(--text-color);
}

.experienceContainer {
    display: flex;
    gap: 20px;
}

.timelineContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 10px 0px;
}

.ballContainer {
    height: 16px;
    width: 16px;
}

.ball {
    background-color: var(--primary-color);
    height: 16px;
    width: 16px;
    border-radius: 8px;
}

.line {
    background-color: var(--text-color);
    width: 2px;
    height: 100%;
    border-radius: 8px;
}

.endingLine {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.line1 {
    background-color: var(--text-color);
    width: 2px;
    height: 90%;
    border-radius: 8px;
}

.line2 {
    background-color: var(--text-color);
    width: 2px;
    height: 10%;
    border-radius: 8px;
}

.line3 {
    background-color: var(--text-color);
    width: 2px;
    height: 2px;
    border-radius: 8px;
}

.detailsContainer {
    padding-bottom: 20px;
}

.detailsContainer h3 {
    color: var(--primary-color);
}

.labelContainer {
    display: flex;
    gap: 20px;
    padding-bottom: 20px;
}

.labeledIcon {
    display: flex;
    align-items: center;
    gap: 5px;
}

.labeledIcon p {
    color: var(--text-seondary-color);
}

.icon{
    transform: scale(1.25);
}

.bullet {
    list-style:disc outside none;
    display:list-item;
    margin-left: 20px;
}

.educationSkillsWrapper {
    color: var(--text-color);
    display: flex;
    gap: 20px;
}

.educationWrapper {
    width: 60%;
}

.skillsWrapper {
    width: 40%;
}

.skillsContainer {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    width: 100%;
}

.skillsContainer p {
    padding: 5px 10px;
    background-color: var(--primary-color);
    border-radius: 20px;
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 900px) {
    .educationSkillsWrapper {
        flex-direction: column;
    }
    .educationWrapper {
        width: 100%;
    }
    
    .skillsWrapper {
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .resumeWrapper {
        width: 70%;
    }
}

@media screen and (max-width: 700px) {
    .resumeWrapper {
        width: 80%;
    }
}

@media screen and (max-width: 600px) {
    .resumeWrapper {
        width: 90%;
    }
}