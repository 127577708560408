.mainContainer {
    padding: 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color);
    color: var(--text-color);
}

.mainContainer h1 {
    font-weight: 900;
    color: var(--text-seondary-color);
}

.aboutWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 60%;
}

.aboutMeContainer {
    display: flex;
    gap: 20px;
    flex-direction: row;
}

.imageContainer img {
    width: 240px;
    border-radius: 20px;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.headerContainer {

}

.descriptionContainer p {
    margin-bottom: 20px;
}

.actionContainer {
    display: flex;
    justify-content: end;
}

.actionContainer button {
    background-color: var(--primary-color);
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    color: var(--text-color);
    font-weight: 800;
    cursor: pointer;
}

.actionContainer button:hover {
    background-color: var(--primary-color-hover);
}

.titleLinksContainer {
    display: flex;
    justify-content: space-between;
}

.location {
    display: flex;
    align-items: center;
    gap: 5px;
}

.location span {
    color: var(--text-seondary-color);
    font-size: 10px;
}

.last-child {
    margin-top: auto;  /* Pushes this child to the end */
}

.contactMeButton {
    background-color: var(--primary-color);
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    color: var(--text-color);
    text-decoration: none;
    font-weight: 800;
    cursor: pointer;
}

.contactMeButton:hover {
    background-color: var(--primary-color-hover);
}

@media screen and (max-width: 800px) {
    .imageContainer img {
        width: 150px;
    }
    .aboutWrapper {
        width: 70%;
    }
}

@media screen and (max-width: 700px) {
    .aboutMeContainer {
        flex-direction: column;
    }
    .actionContainer button {
        width: 100%;
    }
    .imageContainer img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        object-position: top;
    }
    .aboutWrapper {
        width: 80%;
    }
}

@media screen and (max-width: 600px) {
    .aboutWrapper {
        width: 90%;
    }
}