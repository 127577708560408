:root {
    --offset-x: 0px;
    --offset-y: 0px;
  }

.heroContainer {
    background-color: var(--background-color);
    color: var(--text-color);
    height: 95vh;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.heroContentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    height: 95vh;
}

.heroTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}
.heroTextContainer h4 {
    margin: 0px; /* Get rid of the stock spacing*/
    margin-bottom: -10px;
    color: var(--text-seondary-color);
    font-size: 34px;
    font-weight: 300;
}

.heroTextContainer h2 {
    margin: 0px;
    font-size: 50px;
    font-weight: 900;
    text-align: center;
}

.heroBackground {
    position: relative;           /* Enables absolute positioning for the button */
    background-image: url('../../assets/images/heroBackgroundx2.png');
    background-size: cover;           /* Make the background image fully visible without clipping */
    background-position: center;        /* Center the background image */
    background-repeat: no-repeat;       /* Prevent image repetition */
    width: 450px;                         /* Full width of the container */
    height: 330px;
    margin-bottom: 150px;
}

.UIFlowButton {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 30px;
    height: 30px;
    background-color: var(--primary-color);
    border: none;
    border-radius: 20px;
    cursor: pointer;
}

.UIFlowPopup {
    position: absolute;
    top: 0px;
    right: 0px;
    transform: translateX(100%) translatey(-50px);
    width: auto;
    height: 50px;
    margin-left: 10px;
    padding: 0px 20px;
    background-color: #6d6d6d51;
    backdrop-filter: blur(6px);
    border-radius: 30px 30px 30px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity .25s ease-in-out;
}

.UIFlowPopup p {
    color: var(--text-color);
}

.fadeIn {
    opacity: 1;
}

.fadeOut {
    opacity: 0;
}

.hero {
    position: relative;
    width: 360px;
    left: 50%;
    transform: translateX(-50%);
    top: -50px;
}

/* Graphics Positions Drop Shadows and Animations*/
.StarsContainer {
    height: 100px;
    position: absolute;
    top: 200px;
    left: 50px;
}

.TadpoleContainer {
    height: 100px;
    position: absolute;
    right: 80px;
    bottom: 50px;
}

.StarLg {
    position: relative;
    top: calc(0px + var(--offset-y));
    left: calc(0px + var(--offset-x));
    filter: drop-shadow(5px 5px 50px #FE6B49); /* Offset, blur, color */
    animation: pulse-animation-medium 2s infinite ease-in-out; /* 2s pulse, repeats infinitely */
    animation-delay: 0s; /* 1-second delay before the animation starts */
}

.StarMd {
    position: relative;
    top: calc(-50px + var(--offset-y));
    left: calc(80px + var(--offset-x));
    filter: drop-shadow(5px 5px 50px #8F3EFF); /* Offset, blur, color */
    animation: pulse-animation-small 2s infinite ease-in-out; /* 2s pulse, repeats infinitely */
    animation-delay: 1s; /* 1-second delay before the animation starts */
}

.StarSm {
    position: relative;
    top: calc(20px + var(--offset-y));
    left: calc(50px + var(--offset-x));
    filter: drop-shadow(5px 5px 50px #5BFF6B); /* Offset, blur, color */
    animation: pulse-animation-large 2s infinite ease-in-out; /* 2s pulse, repeats infinitely */
    animation-delay: 2s; /* 1-second delay before the animation starts */
}

.TadpoleLg {
    position: relative;
    top: calc(0px - var(--offset-y));
    left: calc(0px - var(--offset-x));
    filter: drop-shadow(5px 5px 50px #5BFF6B); /* Offset, blur, color */
    animation: pulse-animation-large 2s infinite ease-in-out; /* 2s pulse, repeats infinitely */
    animation-delay: 2s; /* 1-second delay before the animation starts */
}

.TadpoleMd {
    position: relative;
    top: calc(-80px - var(--offset-y));
    left: calc(-80px - var(--offset-x));
    filter: drop-shadow(5px 5px 50px #8F3EFF); /* Offset, blur, color */
    animation: pulse-animation-medium 2s infinite ease-in-out; /* 2s pulse, repeats infinitely */
    animation-delay: 1s; /* 1-second delay before the animation starts */
}

.TadpoleSm {
    position: relative;
    top: calc(20px - var(--offset-y));
    left: calc(-200px - var(--offset-x));
    filter: drop-shadow(5px 5px 50px #FE6B49); /* Offset, blur, color */
    animation: pulse-animation-small 2s infinite ease-in-out; /* 2s pulse, repeats infinitely */
    animation-delay: 0s; /* 1-second delay before the animation starts */
}

.wave {
    display: inline-block;
    transform-origin: bottom right;
    animation: none;
}


@keyframes pulse-animation-small {
    0% {
        transform: scale(1); /* Starting size */
        opacity: 1; /* Full opacity */
        rotate: 0deg;
    }
    50% {
        transform: scale(1.01); /* Slightly larger size */
        opacity: 0.9; /* Slightly faded */
        rotate: .5deg;
    }
    100% {
        transform: scale(1); /* Return to original size */
        opacity: 1; /* Return to full opacity */
        rotate: 0deg;
    }
}

@keyframes pulse-animation-medium {
    0% {
        transform: scale(1); /* Starting size */
        opacity: 1; /* Full opacity */
        rotate: 0deg;
    }
    50% {
        transform: scale(1.05); /* Slightly larger size */
        opacity: 0.9; /* Slightly faded */
        rotate: 1deg;
    }
    100% {
        transform: scale(1); /* Return to original size */
        opacity: 1; /* Return to full opacity */
        rotate: 0deg;
    }
}

@keyframes pulse-animation-large {
    0% {
        transform: scale(1); /* Starting size */
        opacity: 1; /* Full opacity */
        rotate: 0deg;
    }
    50% {
        transform: scale(1.1); /* Slightly larger size */
        opacity: 0.9; /* Slightly faded */
        rotate: 2deg;
    }
    100% {
        transform: scale(1); /* Return to original size */
        opacity: 1; /* Return to full opacity */
        rotate: 0deg;
    }
}

@keyframes wave {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(-15deg);
    }
    50% {
      transform: rotate(10deg);
    }
    75% {
      transform: rotate(-10deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

/* Responsive changes */
@media screen and (max-width: 1000px) {
    .heroTextContainer h2 {
        font-size: 42px;
    }
    .heroTextContainer h4 {
        font-size: 30px;
    }
    .heroTextContainer {
        margin-top: 80px;
    }
    .wrapper {
        gap: 0px;
    }
    .StarsContainer {
        transform: scale(.5);
    }
    .TadpoleContainer {
        transform: scale(.5);
    }
    :root {
        --offset-x: 0px;
        --offset-y: 30px;
    }

    .UIFlowPopup {
        top: 0px;
        right: 0px;
        transform: translateX(-5%) translatey(25px);
        margin-left: 10px;
        padding: 0px 20px;
        border-radius: 30px 5px 30px 30px;
        opacity: 1;
    }
}
@media screen and (max-width: 900px) {
    .heroTextContainer h2 {
        font-size: 42px;
    }
    .heroTextContainer h4 {
        font-size: 30px;
    }
    .heroTextContainer {
        margin-top: 80px;
    }
    .StarsContainer {
        left: 30px;
    }
    .TadpoleContainer {
        right: 10px;
    }
}

@media screen and (max-width: 800px) {
    .StarsContainer {
        left: 10px;
    }
    .TadpoleContainer {
        right: -10px;
    }
}

@media screen and (max-width: 700px) {
    .StarsContainer {
        left: -10px;
    }
    .TadpoleContainer {
        right: -30px;
    }
}

@media screen and (max-width: 600px) {
    .heroTextContainer h2 {
        font-size: 30px;
    }
    .heroTextContainer h4 {
        font-size: 24px;
    }
    .heroBackground {
        width: 300px;
        height: 220px;
        margin-bottom: 100px;
    }
    .hero {
        width: 260px;
    }
    .UIFlowButton {
        width: 20px;
        height: 20px;
    }
    .TadpoleContainer {
        bottom: 150px;
    }
}

@media screen and (max-width: 600px) {
    @media screen and (max-height: 650px) {
        .heroContainer {
            height: 600px;
        }
        .heroContentContainer {
            height: 600px;
        }
    }
}

@media screen and (min-width: 600px) {
    @media screen and (max-height: 800px) {
        .heroContainer {
            height: 780px;
        }
        .heroContentContainer {
            height: 780px;
        }
    }
}