.navbar {
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #6d6d6d51;
    backdrop-filter: blur(6px);
    border-radius: 33px;
    padding: 10px;
    display: flex; /* Flexbox to handle layout */
    align-items: center;
    z-index: 1000;
    max-width: 100vw; /* Ensures it doesn’t overflow the viewport width */
}

/* Unordered list inside the navbar */
.nav-items {
    list-style-type: none;
    display: flex;
    box-sizing: border-box;
    transition: transform 0.3s ease; /* Smooth shift transition when button appears */
}

/* List item inside the navbar */
.nav-items li {
    margin: 0 10px; /* Adjust spacing between nav items */
}

/* Link styling within nav items */
.nav-items li a {
    padding: 10px 20px;
    color: white;
    text-decoration: none;
    white-space: nowrap;
}

.nav-items li a:hover {
    background-color: var(--primary-color-hover);
    border-radius: 20px;
}

/* Visited link styling */
.nav-items li a:visited {
    color: white;
    text-decoration: none;
}

/* Scroll-to-top container */
.scrollToTopContainer {
}

/* Scroll-to-top button */
.scrollToTop {
    background-color: var(--primary-color);
    height: 40px;
    width: 40px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0; /* Initially hidden */
    transform: translateY(10px); /* Positioned slightly lower */
    transition: opacity 0.5s ease, transform 0.5s ease; /* Fade and slide transition */
}

/* Show scroll-to-top button */
.scrollToTop.show {
    opacity: 1; /* Visible when scrolled */
    transform: translateY(0); /* Moves up into place */
}

.scrollToTop:hover {
    background-color: var(--primary-color-hover);
}

/* Adjust the hover effect for nav items */
.nav-items li a:hover {
    background-color: var(--primary-color-hover);
    border-radius: 20px;
}

/* Navbar styling for smooth transitions and overall layout */
.navbar ul {
    list-style-type: none;
    display: flex;
    padding: 0;
    margin: 0;
    transition: transform 0.3s ease; /* Transition for the nav items */
}

.navbar ul li {
    margin: 10px 0px;
    padding: 0px 10px;
}

.navbar ul li a {
    padding: 10px 20px;
    color: white;
    text-decoration: none;
    white-space: nowrap;
}

.navbar ul li a:hover {
    background-color: var(--primary-color-hover);
    border-radius: 20px;
}

.navbar ul li a:visited {
    color: white;
    text-decoration: none;
}

.hamburger {
    display: none;
    cursor: pointer;
    color: var(--text-color);
}

@media (max-width: 768px) {
    .navbar {
        left: auto;
        right: 10px;
    }
    .navbar.open {
        left: auto;
        right: 10px;
        background: none;
        backdrop-filter: none;
    }
    .navbar .nav-items {
        display: none; /* Hide by default */
        flex-direction: column;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 200px;
    }

    .nav-items.open {
        display: flex;
        background-color: #6d6d6d51;
        backdrop-filter: blur(6px);
        border-radius: 30px;
        padding: 10px 0px;
    }
    .hamburger {
        display: block;
        height: 40px;
        width: 40px;
        border: none;
        border-radius: 20px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }
}
